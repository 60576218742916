import React from "react"
import { useHistory } from "react-router-dom"
import moment from "moment"
import "moment/locale/es"

const ShowroomListItem = ({ showroom }) => {
  moment.locale("es")
  const history = useHistory()

  return (
    <div className={"grid grid-cols-1 py-5 px-2"}>
      <div role={"button"} tabIndex={-1}
        className={"grid grid-cols-3 text-md"}
        onClick={() => {history.push(`/showroom/${showroom.uuid}`)}}
        onKeyDown={() => {history.push(`/showroom/${showroom.uuid}`)}}
      >
        <div className={"flex flex-col justify-center items-end px-4"}>
          <img className={"w-20"} src={showroom.fotografia ? `/public/${showroom.fotografia}` : "/assets/images/default-foto.jpeg"} alt={showroom.nombre}/>
        </div>
        <div className={"flex flex-col justify-center items-start px-4 col-span-2"}>
          <span className={"font-bold"}>{showroom.nombre}</span>
          <span className={"text-sm"}>{showroom.ubicacion}</span>
        </div>
      </div>
    </div>
  )
}

export default ShowroomListItem
