import axios from "axios";
import i18n from "../i18n";

import authHeader from "./auth-header";

const API_URL = `${process.env.REACT_APP_BACKEND_URL}/users`;

class AuthService {
  login(username, password) {
    return axios
      .post(`${API_URL}/login`, {
        username,
        password
      })
      .then(response => {
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
          i18n.changeLanguage(response.data.dataValues.language).then();
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  refreshUser() {
    return axios
      .get(`${API_URL}/me`, { headers: authHeader() })
      .then(response => {
        if (response.data) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
      });
  }

  resetPasswordWithKey(resetPasswordKey, newPassword) {
    return axios
      .post(`${API_URL}/password/${resetPasswordKey}`, {
        password: newPassword
      })
  }

  requestResetPassword(username) {
    return axios
      .post(`${API_URL}/password/reset`, {
        username
      })
  }
}

export default new AuthService();