import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import {
  getUserShowrooms,
  getShowroomById,
  createShowroom,
  editarShowroom,
  deleteShowroom,
  createMaterial,
  generatePdf,
  attachPdf,
  deleteMaterial
} from "./services/showroom-service";

import Layout from "./components/layout";
import RouteGuard from "./components/router-gard";

import LoginPage from "./pages/AuthPage/login-page";
import NotFoundPage from "./pages/NotFoundPage/not-found-page";
import HomePage from "./pages/HomePage/home-page";
import ShowroomPage from "./pages/ShowroomPage/showroom-page";
import ShowroomMapPage from "./pages/ShowroomMapPage/showroom-map-page";
import UserProfilePage from "./pages/UserProfilePage/user-profile-page";
import UserManagementPage from "./pages/UserManagementPage/user-management-page";
import UserEditPage from "./pages/UserManagementPage/user-edit-page";
import ResetPasswordPage from "./pages/ResetPasswordPage/reset-password-page";

import "./App.css";

function App() {

  return (
    <Router>
      <Switch>
        <RouteGuard path="/showrooms">
          <Layout currentPage={"HomePage"}>
            <HomePage obtenerShowrooms={getUserShowrooms} crearShowroom={createShowroom} crearMaterial={createMaterial}/>
          </Layout>
        </RouteGuard>
        <RouteGuard path="/mapa-showrooms">
          <Layout currentPage={"ShowroomMapPage"}>
            <ShowroomMapPage obtenerShowrooms={getUserShowrooms}/>
          </Layout>
        </RouteGuard>
        <RouteGuard path="/showroom/:showroomId">
          <Layout>
            <ShowroomPage obtenerShowroom={getShowroomById} crearMaterial={createMaterial} generarPdf={generatePdf} adjuntarPdf={attachPdf} editarShowroom={editarShowroom} eliminarShowroom={deleteShowroom} eliminarMaterial={deleteMaterial}/>
          </Layout>
        </RouteGuard>
        <RouteGuard path="/user-profile">
          <Layout>
            <UserProfilePage/>
          </Layout>
        </RouteGuard>
        <RouteGuard adminRoute path="/user-management/edit/:userId">
          <Layout currentPage={"UserManagementPage"}>
            <UserEditPage/>
          </Layout>
        </RouteGuard>
        <RouteGuard adminRoute path="/user-management">
          <Layout currentPage={"UserManagementPage"}>
            <UserManagementPage/>
          </Layout>
        </RouteGuard>
        <Route path="/login">
          <LoginPage/>
        </Route>
        <Route path="/reset-password/:resetPasswordKey/:language">
          <ResetPasswordPage/>
        </Route>
        <RouteGuard path="/">
          <Layout>
            <HomePage obtenerShowrooms={getUserShowrooms} crearShowroom={createShowroom} crearMaterial={createMaterial}/>
          </Layout>
        </RouteGuard>
        <Route path="*" >
          <Layout>
            <NotFoundPage />
          </Layout>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
