import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ShowroomMap from "../../components/showroom-map";
import showroomTipo from "../../data/showroom-tipo.json";
import AuthService from "../../services/auth-service";

const ShowroomMapPage = ({ obtenerShowrooms }) => {
  const { t } = useTranslation()

  const user = AuthService.getCurrentUser()

  const [showrooms, setShowrooms] = useState([])
  const [filteredShowrooms, setFilteredShowrooms] = useState([])
  const [selectedShowroomTipes, setSelectedShowroomTipes] = useState([])
  const [displayAllShowrooms, setDisplayAllShowrooms] = useState(false)

  useEffect(() => {
    setSelectedShowroomTipes(Object.keys(showroomTipo.tipos))
    updateShowrooms()
  }, [obtenerShowrooms, displayAllShowrooms])

  useEffect(() => {
    setFilteredShowrooms(showrooms.filter(s => selectedShowroomTipes.includes(s.tipo)))
  }, [showrooms, selectedShowroomTipes])

  const updateShowrooms = () => {
    obtenerShowrooms(displayAllShowrooms)
      .then((showrooms) => {
        setShowrooms(showrooms.sort((a, b) => b.fecha_creacion > a.fecha_creacion ? 1 : -1))
      })
      .catch((e) => {
        console.error(e)
      })
  }

  const toggleTipo = (tipo) => {
    const index = selectedShowroomTipes.indexOf(tipo)
    if (index > -1) {
      selectedShowroomTipes.splice(index, 1)
      setSelectedShowroomTipes([...selectedShowroomTipes])
    } else {
      setSelectedShowroomTipes([...selectedShowroomTipes, tipo])
    }
  }

  return showrooms && (
    <div className={"container mx-auto p-4 max-w-screen-md"}>
      {/* Showrooms */}
      <div className={"grid grid-cols-1 my-3"}>
        <div className={"flex justify-center my-5"}>
          <h2 className="text-2xl md:text-3xl font-medium tracking-[10px] text-black text-center uppercase">{t("Mapa showrooms")}</h2>
        </div>
      </div>
      <div className={"w-screen left-[50%] right-[50%] ml-[-50vw] mr-[-50vw] relative"}>
        {showrooms &&
          <ShowroomMap
            showrooms={filteredShowrooms}
            containerStyle={{width: "100%", height:"85vh"}}
            googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: "100%" }} />}
            containerElement={<div style={{ height: "85vh" }} />}
            mapElement={<div style={{ height: "100%" }} />}
          />
        }
      </div>
      <div className={"w-full flex flex-col justify-center items-center"}>
        {showrooms &&
          <div className={"w-3/4 grid grid-cols-1 md:w-3/5 md:grid md:grid-cols-2 mt-3 py-4"}>
            {Object.keys(showroomTipo.tipos).map(tipo =>
              <div key={tipo} role="button" tabIndex={-1}
                className={`flex flex-row justify-start items-center p-2 select-none ${selectedShowroomTipes.includes(tipo) ? "opacity-100" : "opacity-25"}`}
                onClick={() => toggleTipo(tipo)} onKeyDown={() => toggleTipo(tipo)}>
                <img className={"w-5 mr-2"} src={`/markers/${showroomTipo.tipos[tipo]?.marker}`}
                  alt={t(showroomTipo.tipos[tipo].literales.es)}/>
                <span className={"text-sm"}>{t(showroomTipo.tipos[tipo].literales.es)}</span>
              </div>
            )}
          </div>
        }
      </div>
      {parseInt(user.dataValues.role) === 2 && (
        <div className={"w-full flex flex-col justify-center items-center"}>
          <label className="relative inline-flex items-center cursor-pointer">
            <input type="checkbox" value="" className="sr-only peer" checked={displayAllShowrooms} onChange={() => setDisplayAllShowrooms(prev => !prev)}/>
            <div
              className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"/>
            <span className="ml-3 text-sm font-medium">{t("Mostrar todos los showrooms")}</span>
          </label>
        </div>
      )}
    </div>
  )
}

export default ShowroomMapPage;