import React, { useEffect, useState } from "react";
import {useTranslation} from "react-i18next";
import DataTable from "react-data-table-component";
import ModalImage from "react-modal-image";
import moment from "moment";

import { HiOutlineX } from "react-icons/hi";

import AuthService from "../../services/auth-service";

import ShowroomListItem from "../../components/showroom-list-item";

import ShowroomForm from "../../forms/showroom-form";
import MaterialForm from "../../forms/material-form";
import EmptyDataTable from "../../components/empty-data-table";

const HomePage = ({ obtenerShowrooms, crearShowroom, crearMaterial }) => {
  const { t } = useTranslation()

  const [showrooms, setShowrooms] = useState([])
  const [openShowroomForm, setOpenShowroomForm] = useState(false)
  const [openMaterialForm, setOpenMaterialForm] = useState(false)

  const [materialColumns, setMaterialColumns] = useState([])
  const [materialesData, setMaterialesData] = useState([])
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const user = AuthService.getCurrentUser()
    let columns = [
      {
        name: t("Showroom"),
        selector: row => row.showroom,
        sortable: true,
        grow: 2
      },
      {
        name: t("Tipo"),
        selector: row => row.tipo.charAt(0).toUpperCase() + row.tipo.slice(1),
        sortable: true
      },
      {
        name: t("Material"),
        selector: row => `${row.material} ${row.espesor} ${row.acabado}`,
        sortable: true,
        grow: 3
      },
      {
        name: t("Cantidad"),
        selector: row => row.cantidad || "N/A",
        sortable: true
      },
      {
        name: t("Fecha"),
        selector: row => moment(row.fecha).format("L LTS"),
        sortable: true,
        sortFunction: (a, b) => a.fecha > b.fecha ? 1 : -1
      },
      {
        name: t("Fotografías"),
        cell: (data) => (
          <div className={"w-full flex flex-row justify-center items-center"}>
            {data.fotografias && data.fotografias.map(f =>
              <ModalImage key={f} className={"w-10"}
                small={`/public/${f}`}
                large={`/public/${f}`}
                alt={data.material}
              />
            )}
            {(!data.fotografias || data.fotografias.length === 0) && <span>N/A</span>}
          </div>
        )
      }
    ];

    if (user.dataValues.role > 0) {
      columns = [{
        name: t("Usuario"),
        selector: row => row.usuario,
        sortable: true
      }, ...columns]
    }

    setMaterialColumns(columns)
  }, []);

  useEffect(() => {
    setLoading(true)
    updateShowrooms()
  }, [obtenerShowrooms])

  const updateShowrooms = () => {
    obtenerShowrooms()
      .then((showrooms) => {
        setShowrooms(showrooms.sort((a, b) => b.fecha_creacion > a.fecha_creacion ? 1 : -1))
        const materiales = showrooms.reduce((_materiales, showroom) => {
          const showroomMateriales = showroom.materiales ? showroom.materiales.map((material) => ({
            showroom: showroom.nombre,
            tipo: "material",
            material: material.material,
            acabado: material.acabado || "",
            espesor: material.espesor || "",
            cantidad: material.cantidad,
            fecha: showroom.fecha_creacion,
            fotografias: material.fotografias,
            usuario: showroom.user.username
          })) : []
          const showroomDisplay = showroom.displays ? showroom.displays.map((display) => ({
            showroom: showroom.nombre,
            tipo: "display",
            material: display.tipo,
            acabado: "",
            espesor: "",
            cantidad: null,
            fecha: showroom.fecha_creacion,
            fotografias: display.fotografias,
            usuario: showroom.user.username
          })) : []

          return [..._materiales, ...showroomMateriales, ...showroomDisplay]
        }, [])
        setMaterialesData(materiales)
        setLoading(false)
      })
      .catch((e) => {
        console.error(e)
        setLoading(false)
      })
  }

  return showrooms && (
    <div className={"container p-4"}>

      <div className={"max-w-screen-md mx-auto"}>
        {/* Showrooms */}
        <div className={"grid grid-cols-1 my-3"}>
          <div className={"flex justify-center my-5"}>
            <h2 className="text-2xl md:text-3xl font-medium tracking-[10px] text-black text-center uppercase">Showrooms</h2>
          </div>
        </div>
        <div className={"relative grid grid-cols-2 my-3"}>
          {showrooms && showrooms.map((showroom) =>
            <ShowroomListItem showroom={showroom} key={showroom.uuid}/>
          )}
          {/* Empty list */}
        </div>
        <div className={`grid grid-cols-1 my-3 ${openShowroomForm ? "hidden" : "relative"}`}>
          <div className={"flex flex-row justify-center my-5"}>
            <div role="button" tabIndex={-1}
              className={""}
              onClick={() => {setOpenShowroomForm(true)}}
              onKeyDown={() => {setOpenShowroomForm(true)}}
            >
              <div className={"px-4 py-3 flex flex-row justify-center items-center items-center border border-neutral-800 rounded-full"}>
                <span className={"text-black text-md tracking-widest"}>{t("Nuevo showroom")}</span>
                <img src={"/assets/images/icon-plus.svg"} alt={"Nuevo showroom"} className={"ml-2 w-4 h-4"}/>
              </div>
            </div>
          </div>
        </div>
        <div className={`w-screen left-[50%] right-[50%] ml-[-50vw] mr-[-50vw] bg-neutral-300 mb-3 mt-5 ${openShowroomForm ? "relative" : "hidden"}`}>
          <div className={"container mx-auto p-4 max-w-screen-md"}>
            <div className={"grid grid-cols-1"}>
              <div className={"flex justify-center my-5"}>
                <h2 className="flex flex-row text-lg font-medium tracking-[10px] text-black text-center uppercase">{t("Nuevo showroom")} <HiOutlineX className={"ml-3 mt-1 text-xl cursor-pointer"} onClick={() => {setOpenShowroomForm(false)}}/></h2>
              </div>
              <div className={"flex justify-center"}>
                <ShowroomForm crearShowroom={crearShowroom} onShowroomCreated={() => {updateShowrooms(); setOpenShowroomForm(false);}}/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={"max-w-screen-lg mx-auto"}>
        {/* Materiales */}
        <div className={"grid grid-cols-1 my-3 mt-5"}>
          <div className={"flex justify-center my-5"}>
            <h2 className="text-2xl md:text-3xl font-medium tracking-[10px] text-black text-center uppercase">{t("Materiales")}</h2>
          </div>
        </div>
        <DataTable
          columns={materialColumns}
          data={materialesData}
          pagination
          progressPending={loading}
          noDataComponent={<EmptyDataTable/>}
        />
        <div className={`grid grid-cols-1 my-3 ${openMaterialForm ? "hidden" : "relative"}`}>
          <div className={"flex flex-row justify-center my-5"}>
            <div role="button" tabIndex={-1}
              className={""}
              onClick={() => {setOpenMaterialForm(true)}}
              onKeyDown={() => {setOpenMaterialForm(true)}}
            >
              <div className={"px-4 py-2 flex flex-row justify-center items-center items-center border border-neutral-800 rounded-full"}>
                <span className={"text-black text-md tracking-widest"}>{t("Nuevo material")}</span>
                <img src={"/assets/images/icon-plus.svg"} alt={"Nuevo showroom"} className={"ml-2 w-4 h-4"}/>
              </div>
            </div>
          </div>
        </div>
        <div className={`w-screen left-[50%] right-[50%] ml-[-50vw] mr-[-50vw] bg-neutral-300 mb-3 mt-5 ${openMaterialForm ? "relative" : "hidden"}`}>
          <div className={"container mx-auto p-4 max-w-screen-md"}>
            <div className={"grid grid-cols-1 "}>
              <div className={"flex justify-center my-5"}>
                <h2 className="flex flex-row text-lg font-medium tracking-[10px] text-black text-center uppercase">{t("Nuevo material")} <HiOutlineX className={"ml-3 mt-1 text-xl cursor-pointer"} onClick={() => {setOpenMaterialForm(false)}}/></h2>
              </div>
              <div className={"flex justify-center"}>
                <MaterialForm crearMaterial={crearMaterial} onMaterialCreated={() => {updateShowrooms(); setOpenMaterialForm(false)}} showroomsList={showrooms}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePage;