import axios from "axios";

import authHeader from "./auth-header"

const API_URL = process.env.REACT_APP_BACKEND_URL;

axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
  if (err.response.status === 401 && !err.config.url.includes("/login")) {
    window.location = "/login"
  }
  return Promise.reject(err)
})

export const getUserShowrooms = (all) => {
  return new Promise((res, rej) => {
    const url = `${API_URL}/showroom${all ? "?all=true" : ""}`
    axios.get(url, { headers: authHeader() })
      .then(response => res(response.data))
      .catch(rej)
  })
}

export const getShowroomById = (showroomId) => {
  return new Promise((res, rej) => {
    axios.get(`${API_URL}/showroom/${showroomId}`, { headers: authHeader() })
      .then(response => res(response.data))
      .catch(rej)
  })
}

export const createShowroom = (showroom) => {
  return new Promise((res, rej) => {
    const formData = new FormData()
    formData.append("nombre", showroom.nombre)
    formData.append("ubicacion", showroom.ubicacion)
    formData.append("ubicacionId", showroom.ubicacionId)
    formData.append("tipo", showroom.tipo)
    formData.append("telefono", showroom.telefono)
    formData.append("email", showroom.email)
    formData.append("condiciones", showroom.condiciones)
    formData.append("fotografia", showroom.fotografia)
    axios.post(`${API_URL}/showroom`, formData, { headers: authHeader() })
      .then(response => res(response.data))
      .catch(rej)
  })
}

export const editarShowroom =  (showroomId, showroom) => {
  return new Promise((res, rej) => {
    const formData = {}
    formData.nombre = showroom.nombre
    formData.tipo = showroom.tipo
    formData.telefono = showroom.telefono
    formData.email = showroom.email

    if (showroom.ubicacionId) {
      formData.ubicacion = showroom.ubicacion
      formData.ubicacionId = showroom.ubicacionId
    }

    axios.put(`${API_URL}/showroom/${showroomId}`, formData, { headers: authHeader() })
      .then(response => res(response.data))
      .catch(rej)
  })
}

export const createMaterial = (showroomId, material) => {
  return new Promise((res, rej) => {
    const formData = new FormData()
    formData.append("tipo", material.tipo)
    formData.append("material", material.material)
    formData.append("acabado", material.acabado)
    formData.append("espesor", material.espesor)
    formData.append("cantidad", material.cantidad)
    formData.append("condiciones", material.condiciones)
    if (material.fotografias) {
      for (let i = 0; i < material.fotografias.length; i++) {
        formData.append("fotografias", material.fotografias[i])
      }
    }
    axios.post(`${API_URL}/showroom/${showroomId}/material`, formData, { headers: authHeader() })
      .then(response =>  res(response.data))
      .catch(rej)
  })
}

export const generatePdf = (showroomId) => {
  return new Promise((res, rej) => {
    axios.get(`${API_URL}/showroom/${showroomId}/pdf`,{ headers: authHeader(), responseType: "blob" })
      .then(response => res(response.data))
      .catch(rej)
  })
}

export const attachPdf = (showroomId, pdf) => {
  return new Promise((res, rej) => {
    const formData = new FormData()
    formData.append("pdf", pdf)
    axios.post(`${API_URL}/showroom/${showroomId}/pdf`, formData, { headers: authHeader() })
      .then(response =>  res(response.data))
      .catch(rej)
  })
}

export const deleteShowroom = (showroomId) => {
  return new Promise((res, rej) => {
    axios.delete(`${API_URL}/showroom/${showroomId}`,{ headers: authHeader() })
      .then(response => res(response.data))
      .catch(rej)
  })
}

export const deleteMaterial = (showroomId, materialType, index) => {
  return new Promise((res, rej) => {
    axios.delete(`${API_URL}/showroom/${showroomId}/material/${materialType}/${index}`,{ headers: authHeader() })
      .then(response => res(response.data))
      .catch(rej)
  })
}