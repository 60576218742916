import React from "react";
import { useTranslation } from "react-i18next";

export default function EmptyDataTable() {
  const { t } = useTranslation()
  return (
    <p className="w-full text-center p-5">
      {t("No hay registros")}
    </p>
  )
}
