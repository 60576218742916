import React, {useState, useEffect} from "react";
import { useTranslation } from "react-i18next";

import UserRoles from "../data/user-roles.json"
import AuthService from "../services/auth-service";
import { getUsers, getUserData } from "../services/user-service";

const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g; // eslint-disable-line

export default function EditUserForm ({ userId, updateUser, onUserUpdated, onCancel }) {
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [currentUserRole, setCurrentUserRole] = useState(null)
  const [distribuidoresAdmin, setDistribuidoresAdmin] = useState([])

  const [formData, setFormData] = useState({
    username: "",
    nombre: "",
    telefono: "",
    direccion: "",
    pais: "",
    codigo: "",
    adv: "",
    language: "es",
    role: 0
  })

  const [formErrors, setFormErrors] = useState({
    username: null,
    role: null,
    nombre: null,
    telefono: null,
    direccion: null,
    pais: null,
    codigo: null,
    language: null,
    adv: null
  })

  useEffect(() => {
    const user = AuthService.getCurrentUser()
    setCurrentUserRole(UserRoles.roles.filter(r => parseInt(r.id) === parseInt(user.dataValues.role)).pop())
    if (user.dataValues.role > 1) {
      getUsers().then((users) => {
        setDistribuidoresAdmin(users.filter(u => u.role === 1))
      })
    }
  }, [])

  useEffect(() => {
    getUserData(userId).then((userData) => {
      setFormData(userData)
    })
  }, [])

  const handleSubmitForm = async () => {
    const _formErrors = {
      username: null,
      role: null
    }

    if (formData.role === null) {
      _formErrors.role = "Debe seleccionar un rol"
    }

    if (formData.username.length === 0 || !formData.username.match(isValidEmail)) {
      _formErrors.username = "El usuario introducido no es correcto"
    }
    setFormErrors(_formErrors)

    if (!_formErrors.username && !_formErrors.role) {
      try {
        setLoading(true)
        await updateUser(userId, formData)
        onUserUpdated()
        setFormData({
          username: "",
          nombre: "",
          telefono: "",
          direccion: "",
          pais: "",
          codigo: "",
          role: 0
        })
      } catch (e) {
        setFormErrors({
          username: "Error al modificar el usuario",
          role: null
        })
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <form className={"w-full md:w-3/5 lg:w-2/3"}>
      <div className="relative mb-3">
        <select id="role"
          className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
          placeholder=" "
          value={formData["role"]}
          onChange={e => setFormData({...formData, "role": e.target.value})}>
          {currentUserRole && UserRoles.roles.filter(r => currentUserRole.rolesAllowed.indexOf(r.id) > -1).map(role => <option key={role.id} selected={role.id === 0} value={role.id}>{t(role.literal)}</option>)}
        </select>
        <label htmlFor="role"
          className="absolute text-sm text-neutral-800 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t("Rol")}</label>
      </div>
      {formErrors.role && (
        <div className="pl-5 mb-3 text-normal text-red-700">
          {t(formErrors.role)}
        </div>
      )}

      <div className="relative mb-3">
        <input id="username" type="text"
          className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
          placeholder=" "
          value={formData["username"]}
          onChange={e => setFormData({...formData, username: e.target.value})}/>
        <label htmlFor="username"
          className="absolute text-sm text-neutral-800 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{ t("Email") }</label>
      </div>
      {formErrors.username && (
        <div className="pl-5 mb-3 text-normal text-red-700">
          {t(formErrors.username)}
        </div>
      )}

      <div className="relative mb-3">
        <input id="nombre" type="text"
          className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
          placeholder=" "
          value={formData["nombre"]}
          onChange={e => setFormData({...formData, nombre: e.target.value})}/>
        <label htmlFor="nombre"
          className="absolute text-sm text-neutral-800 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{ t("Nombre") }</label>
      </div>
      {formErrors.nombre && (
        <div className="pl-5 mb-3 text-normal text-red-700">
          {t(formErrors.nombre)}
        </div>
      )}

      <div className="relative mb-3">
        <select id="language"
          className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
          placeholder=" "
          value={formData["language"]}
          onChange={e => setFormData({...formData, "language": e.target.value})}>
          <option selected>{t("Selecciona un idioma")}</option>
          <option key={"es"} value={"es"}>{t("Español")}</option>
          <option key={"en"} value={"en"}>{t("Inglés")}</option>
        </select>
        <label htmlFor="language"
          className="absolute text-sm text-neutral-800 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t("Idioma")}</label>
      </div>
      {formErrors.language && (
        <div className="pl-5 mb-3 text-normal text-red-700">
          {t(formErrors.language)}
        </div>
      )}

      {parseInt(formData.role) === 1 && (
        <>
          <div className="relative mb-3">
            <input id="telefono" type="text"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
              placeholder=" "
              value={formData["telefono"]}
              onChange={e => setFormData({...formData, telefono: e.target.value})}/>
            <label htmlFor="telefono"
              className="absolute text-sm text-neutral-800 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{ t("Teléfono") }</label>
          </div>
          {formErrors.telefono && (
            <div className="pl-5 mb-3 text-normal text-red-700">
              {t(formErrors.telefono)}
            </div>
          )}
        </>
      )}

      {parseInt(formData.role) === 1 && (
        <>
          <div className="relative mb-3">
            <select id="adv"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
              placeholder=" "
              value={formData["adv"]}
              onChange={e => setFormData({...formData, "adv": e.target.value})}>
              <option selected>{t("Selecciona un ADV")}</option>
              <option key={"enita"} value={"enita@ascale.es"}>enita@ascale.es</option>
              <option key={"ctorres"} value={"ctorres@ascale.es"}>ctorres@ascale.es</option>
            </select>
            <label htmlFor="adv"
              className="absolute text-sm text-neutral-800 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t("ADV")}</label>
          </div>
          {formErrors.adv && (
            <div className="pl-5 mb-3 text-normal text-red-700">
              {t(formErrors.adv)}
            </div>
          )}
        </>
      )}

      {parseInt(formData.role) === 1 && (
        <>
          <div className="relative mb-3">
            <input id="direccion" type="text"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
              placeholder=" "
              value={formData["direccion"]}
              onChange={e => setFormData({...formData, direccion: e.target.value})}/>
            <label htmlFor="direccion"
              className="absolute text-sm text-neutral-800 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{ t("Dirección") }</label>
          </div>
          {formErrors.direccion && (
            <div className="pl-5 mb-3 text-normal text-red-700">
              {t(formErrors.direccion)}
            </div>
          )}
          <div className="relative mb-3">
            <input id="pais" type="text"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
              placeholder=" "
              value={formData["pais"]}
              onChange={e => setFormData({...formData, pais: e.target.value})}/>
            <label htmlFor="pais"
              className="absolute text-sm text-neutral-800 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{ t("País") }</label>
          </div>
          {formErrors.pais && (
            <div className="pl-5 mb-3 text-normal text-red-700">
              {t(formErrors.pais)}
            </div>
          )}
          <div className="relative mb-3">
            <input id="codigo" type="text"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
              placeholder=" "
              value={formData["codigo"]}
              onChange={e => setFormData({...formData, codigo: e.target.value})}/>
            <label htmlFor="codigo"
              className="absolute text-sm text-neutral-800 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{ t("Código") }</label>
          </div>
          {formErrors.codigo && (
            <div className="pl-5 mb-3 text-normal text-red-700">
              {t(formErrors.codigo)}
            </div>
          )}
        </>
      )}

      {currentUserRole && parseInt(currentUserRole.id) > 1 && parseInt(formData.role) === 0 && (
        <>
          <div className="relative mb-3">
            <select id="parent"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
              placeholder=" "
              value={formData["_parent"]}
              onChange={e => setFormData({...formData, "_parent": e.target.value})}>
              <option selected>{t("Selecciona un Distribudor Administrador")}</option>
              {distribuidoresAdmin.length && distribuidoresAdmin.map(da =>
                <option key={da.id} value={da.id}>
                  {da.username}
                </option>
              )}
            </select>
            <label htmlFor="parent"
              className="absolute text-sm text-neutral-800 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t("Distribudor Administrador")}</label>
          </div>
          {formErrors.parent && (
            <div className="pl-5 mb-3 text-normal text-red-700">
              {t(formErrors.parent)}
            </div>
          )}
        </>
      )}

      <div className="flex flex-row">
        <button
          className={"mt-3 mr-2 px-4 py-2 rounded-full text-md bg-black text-white tracking-widest flex flex-row justify-center items-center border border-black disabled:opacity-30"}
          type="button" onClick={handleSubmitForm} disabled={loading}>
          <span>{ t("Guardar cambios") }</span>
        </button>
        <button
          className={"mt-3 px-4 py-2 rounded-full text-md text-black tracking-widest flex flex-row justify-center items-center border border-neutral-800 disabled:opacity-30"}
          type="button" onClick={onCancel} disabled={loading}>
          <span>{ t("Cancelar") }</span>
        </button>
      </div>
    </form>
  )
}