import React from "react"
import { useHistory } from "react-router-dom";
import { GoogleMap, Marker } from "@react-google-maps/api";

import showroomTipo from "../data/showroom-tipo.json"

const defaultContainerStyle = {
  width: "100%",
  height: "70vh"
};

const mapOptions = {
  clickableIcons: false,
  disableDefaultUI: true,
  fullscreenControl: false,
  mapTypeControl: false,
  rotateControl: false,
  scaleControl: false,
  zoomControl: false
}

const ShowroomMap = (props) => {
  const history = useHistory()

  const containerStyle = props.containerStyle || defaultContainerStyle;

  const center = props.showrooms && props.showrooms.length === 1 ?
    {
      lat: +props.showrooms[0].lat,
      lng: +props.showrooms[0].lng
    } :
    {
      lat: 40.4165,
      lng: -3.70256
    }

  return (
    <GoogleMap
      className={"w-full"}
      mapContainerStyle={containerStyle}
      center={center}
      zoom={props.zoom || 5}
      mapTypeId={"hybrid"}
      options={mapOptions}
    >
      {
        props.showrooms && props.showrooms.map(showroom => {
          return (
            <Marker
              className={"w-10"}
              key={showroom.uuid}
              icon={{
                url: `/markers/${showroomTipo.tipos[showroom.tipo]?.marker}`,
                scaledSize: new window.google.maps.Size(21, 27)
              }}
              position={{lat: +showroom.lat, lng: +showroom.lng}}
              onClick={() => history.push(`/showroom/${showroom.uuid}`)}
            />
          )
        })
      }
    </GoogleMap>
  )
}

export default ShowroomMap