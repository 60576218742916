import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";

import { HiTrash, HiOutlineBan, HiOutlineCheckCircle, HiPencil } from "react-icons/hi";

import { createUser, getUsers, deleteUser, activateUser, deactivateUser } from "../../services/user-service"
import EmptyDataTable from "../../components/empty-data-table";
import NewUserForm from "../../forms/new-user-form";

const UserManagementPage = () => {
  const [usersData, setUsersData] = useState([])
  const [userColumns, setUserColumns] = useState([])
  const [loading, setLoading] = useState(false);

  const history = useHistory()
  const { t } = useTranslation()

  const updateUsers = () => {
    setLoading(true)
    getUsers()
      .then((users) => {
        setUsersData(users)
      })
      .catch(console.error)
      .finally(() =>{
        setLoading(false)
      })
  }

  const handleRemove = async (userId) => {
    if(window.confirm(t("¿Está seguro de eliminar al usuario?"))) {
      await deleteUser(userId)
      await updateUsers()
    }
  }

  const handleDeactivate = async (userId) => {
    await deactivateUser(userId)
    await updateUsers()
  }

  const handleActivate = async (userId) => {
    await activateUser(userId)
    await updateUsers()
  }

  const handleEdit = (userId) => {
    history.push(`/user-management/edit/${userId}`)
  }

  useEffect(() => {
    const columns = [
      {
        name: t("Usuario"),
        selector: row => row.username,
        sortable: true
      },
      {
        name: t("Rol"),
        selector: row => t(`_role_${row.role}`),
        sortable: true
      },
      {
        name: t("Activo"),
        selector: row => t(row.active ? "Sí" : "No"),
        sortable: true
      },
      {
        name: t("Showrooms"),
        selector: row => row.showroomCount,
        sortable: true
      },
      {
        cell: (data) => <div className={"flex flew-row justify-center align-center"}>
          {data.active && <button className={"mx-2"} title={t("Desactivar usuario")} onClick={() => handleDeactivate(data.id)}><HiOutlineBan/></button>}
          {!data.active && <button className={"mx-2"} title={t("Activar usuario")} onClick={() => handleActivate(data.id)}><HiOutlineCheckCircle/></button>}
          <button className={"mx-2"} title={t("Editar usuario")} onClick={() => handleEdit(data.id)}><HiPencil/></button>
          <button className={"mx-2"} title={t("Eliminar usuario")} onClick={() => handleRemove(data.id)}><HiTrash/></button>
        </div> ,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ]
    setUserColumns(columns)
    updateUsers()
  }, [])

  return (
    <div className={"container mx-auto p-4 max-w-screen-md"}>
      <div className={"grid grid-cols-1 my-3"}>
        <div className={"flex flex-col justify-center my-5"}>
          <h2 className="text-2xl md:text-3xl font-medium tracking-[10px] text-black text-center uppercase">{ t("Gestión usuarios") }</h2>
        </div>
      </div>

      <div className={"grid grid-cols-1"}>
        <div className={"flex justify-center my-5"}>
          <h2 className="flex flex-row text-lg font-medium tracking-[5px] text-black text-center uppercase">{ t("Nuevo usuario") } </h2>
        </div>
        <div className={"flex justify-center p-5"}>
          <NewUserForm createUser={createUser} onUserCreated={updateUsers}/>
        </div>
      </div>

      <div className={"grid grid-cols-1 my-3 mt-5"}>
        <div className={"flex justify-center my-5"}>
          <h2 className="text-2xl md:text-3xl font-medium tracking-[10px] text-black text-center uppercase">{t("Usuarios")}</h2>
        </div>
        <DataTable
          columns={userColumns}
          data={usersData}
          pagination
          progressPending={loading}
          noDataComponent={<EmptyDataTable/>}
        />
      </div>

    </div>
  )
}

export default UserManagementPage;