import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import AuthService from "../../services/auth-service";

import ResetPasswordForm from "../../forms/reset-password-form";

function ResetPasswordPage() {
  const history = useHistory()

  const { t, i18n } = useTranslation()
  const { resetPasswordKey, language } = useParams()

  const [passwordReseted, setPasswordReseted] = useState(false)

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language)
    }
  }, [])

  const resetPassword = async (newPassword) => {
    return AuthService.resetPasswordWithKey(resetPasswordKey, newPassword)
  }

  const onResetPassword = () => {
    setPasswordReseted(true)
  }

  return (
    <div className={"container mx-auto p-4 max-w-screen-md"}>
      <div className={"grid grid-cols-1 my-3"}>
        <div className={"flex flex-col justify-center my-5"}>
          <h2 className="text-2xl md:text-3xl font-medium tracking-[10px] text-black text-center uppercase">{t("Nueva contraseña")}</h2>
        </div>
      </div>

      <div className={"grid grid-cols-1"}>
        <div className={"flex justify-center my-5"}>
          <h2 className="flex flex-row text-lg font-medium tracking-[5px] text-black text-center uppercase">{ t("Resetear contraseña") } </h2>
        </div>
        {!passwordReseted &&
          <div className={"flex justify-center p-5"}>
            <ResetPasswordForm resetPassword={resetPassword} onResetPassword={onResetPassword}/>
          </div>
        }
        {passwordReseted &&
          <div className={"flex flex-col justify-center items-center my-5"}>
            <span className={"text-md text-black text-center mt-5 mb-3"}>{t("Tu nueva contraseña ha sido creada. Ya puedes acceder con las nuevas credenciales.")}</span>
            <span className={"p-4 my-5 text-sm text-center text-neutral-500"}><a href={"#"} className="text-blue-600 hover:underline cursor-pointer" onClick={() => history.push("/login")}>{t("Iniciar sesión")}</a></span>
          </div>
        }
      </div>
    </div>
  );
}

export default ResetPasswordPage;