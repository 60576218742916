import React, {useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import AuthService from "../../services/auth-service";
import { changeUserLanguage } from "../../services/user-service";

import ResetPasswordForm from "../../forms/reset-password-form";

const UserProfilePage = () => {
  const [user, setUser] = useState(null)

  const { t, i18n } = useTranslation()
  const history = useHistory()

  AuthService.getCurrentUser()

  const updateUser = async () => {
    await AuthService.refreshUser()
    setUser(AuthService.getCurrentUser())
  }

  const logout = () => {
    AuthService.logout()
    history.replace("/")
  }

  const handleLangChange = async (lang) => {
    await changeUserLanguage(lang)
    updateUser()
  }

  useEffect(() => {
    updateUser()
  }, [])

  useEffect(() => {
    if (user) {
      i18n.changeLanguage(user.dataValues.language)
    }
  }, [user])

  return (
    <div className={"container mx-auto p-4 max-w-screen-md"}>
      <div className={"grid grid-cols-1 my-3"}>
        <div className={"flex flex-col justify-center my-5"}>
          <h2 className="text-2xl md:text-3xl font-medium tracking-[10px] text-black text-center uppercase">{t("¡Hola user!", {user: user ? user.dataValues.username : ""})}</h2>
          {user && user.dataValues.role > 0 && <p className="text-md font-medium text-teal-800 text-center uppercase">{ t("Administrador") }</p>}
        </div>
      </div>

      <div className={"grid grid-cols-1"}>
        <div className={"flex justify-center my-5"}>
          <h2 className="flex flex-row text-lg font-medium tracking-[5px] text-black text-center uppercase">{ t("Selección idioma") } </h2>
        </div>
        <div className={"flex flex-col md:flex-row justify-center p-5"}>
          <div role="button" tabIndex={-1}
            className={"w-full my-3 md:w-auto md:mx-3"}
            onClick={() => handleLangChange("es")}
            onKeyDown={() => handleLangChange("es")}
          >
            <div className={`${i18n.language === "es" ? "bg-neutral-800" : "bg-transparent"} px-4 py-2 flex flex-row justify-center items-center items-center border border-neutral-800 rounded-full`}>
              <span className={`${i18n.language === "es" ? "text-white" : "text-black"} text-md tracking-widest`}>Español</span>
            </div>
          </div>
          <div role="button" tabIndex={-1}
            className={"w-full my-3 md:w-auto md:mx-3"}
            onClick={() => handleLangChange("en")}
            onKeyDown={() => handleLangChange("en")}
          >
            <div className={`${i18n.language === "en" ? "bg-neutral-800" : "bg-transparent"} px-4 py-2 flex flex-row justify-center items-center items-center border border-neutral-800 rounded-full`}>
              <span className={`${i18n.language === "en" ? "text-white" : "text-black"} text-md tracking-widest`}>English</span>
            </div>
          </div>
        </div>
      </div>

      <div className={"grid grid-cols-1"}>
        <div className={"flex justify-center my-5"}>
          <h2 className="flex flex-row text-lg font-medium tracking-[5px] text-black text-center uppercase">{ t("Resetear contraseña") } </h2>
        </div>
        <div className={"flex justify-center p-5"}>
          <ResetPasswordForm resetPassword={console.log} onResetPassword={console.log}/>
        </div>
      </div>

      <hr className={"border-neutral-500 my-5"}/>

      <div className={"grid grid-cols-1 my-3"}>
        <div className={"flex flex-row justify-center my-5"}>
          <div role="button" tabIndex={-1}
            className={""}
            onClick={logout}
            onKeyDown={logout}
          >
            <div className={"px-4 py-2 flex flex-row justify-center items-center items-center border border-neutral-800 rounded-full"}>
              <span className={"text-black text-md tracking-widest"}>{ t("Cerrar sesión") }</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserProfilePage;