import axios from "axios";
import authHeader from "./auth-header";

const API_URL = `${process.env.REACT_APP_BACKEND_URL}/users`;


export const createUser = (newUserData) => {
  return axios
    .post(`${API_URL}/signup`, newUserData, {
      headers: authHeader()
    })
}

export const getUsers = () => {
  return new Promise((resolve, reject) => {
    axios.get(`${API_URL}/`, { headers: authHeader() })
      .then((response) => {
        resolve(response.data)
      })
      .catch(reject)
  })
}

export const getUserData = (userId) => {
  return new Promise((resolve, reject) => {
    axios.get(`${API_URL}/${userId}`, { headers: authHeader() })
      .then((response) => {
        resolve(response.data)
      })
      .catch(reject)
  })
}

export const deleteUser = (userId) => {
  return axios.delete(`${API_URL}/${userId}`, {headers: authHeader()})
}

export const activateUser = (userId) => {
  return axios.post(`${API_URL}/${userId}/active`, {}, {headers: authHeader()})
}

export const deactivateUser = (userId) => {
  return axios.delete(`${API_URL}/${userId}/active`, {headers: authHeader()})
}

export const changeUserLanguage = (lang) => {
  return axios.put(`${API_URL}/lang/${lang}`, {}, {headers: authHeader()})
}

export const updateUser = (userId, userData) => {
  return axios
    .put(`${API_URL}/${userId}`, userData, {
      headers: authHeader()
    })
}