import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import materials from "../data/materials.json"

export default function MaterialForm ({crearMaterial, onMaterialCreated, showroomId, showroomsList}) {

  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)

  const [formData, setFormData] = useState({
    tipo: "",
    material: "",
    acabado: "",
    espesor: "",
    cantidad: 0,
    fotografias: null,
    condiciones: false
  })

  const [formErrors, setFormErrors] = useState({
    tipo: null,
    material: null,
    acabado: null,
    espesor: null,
    cantidad: null,
    fotografias: null,
    condiciones: null
  })

  const handleNewMaterial = () => {
    const _showroomId = showroomId !== undefined ? showroomId : formData.showroom
    const {tipo, material, acabado, espesor, cantidad, condiciones} = formData
    let _formErrors = {
      tipo: null,
      material: null,
      acabado: null,
      espesor: null,
      cantidad: null,
      fotografias: null,
      condiciones: null,
      showroom: null
    }
    let error = false

    if (!_showroomId) {
      error = true
      _formErrors = {..._formErrors, showroom: "Selecciona un showroom"}
    }

    if (!tipo || tipo.length < 2) {
      error = true
      _formErrors = {..._formErrors, tipo: "Selecciona un tipo válido"}
    }

    if (tipo && tipo.length > 0 && tipo === "material" && (!material || material.length < 2)) {
      error = true
      _formErrors = {..._formErrors, material: "Selecciona un material"}
    }

    if (tipo && tipo.length > 0 && tipo === "material" && (!acabado || acabado.length < 1)) {
      error = true
      _formErrors = {..._formErrors, acabado: "Selecciona un acabado"}
    }

    if (tipo && tipo.length > 0 && tipo === "material" && (!espesor || espesor.length < 1)) {
      error = true
      _formErrors = {..._formErrors, espesor: "Selecciona un espesor"}
    }

    if (tipo && tipo.length > 0 && tipo === "material" && (!cantidad || cantidad < 1)) {
      error = true
      _formErrors = {..._formErrors, cantidad: "Introduce una cantidad válida"}
    }

    if (!condiciones) {
      error = true
      _formErrors = {..._formErrors, condiciones: "Acepta las condiciones"}
    }

    setFormErrors(_formErrors)

    if (!error) {
      setLoading(true)
      crearMaterial(_showroomId, {
        tipo: formData.tipo,
        material: formData.material.value,
        acabado: formData.acabado,
        espesor: formData.espesor,
        cantidad: formData.cantidad,
        fotografias: formData.fotografias
      })
        .then(() => {
          setFormData({
            tipo: "",
            material: "",
            acabado: "",
            espesor: "",
            cantidad: 0,
            fotografias: [],
            condiciones: false
          })
          onMaterialCreated()
        })
        .catch((e) => {
          console.error(e)
          setFormErrors({
            generic: "Error al crear material"
          })
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const mataerialOptions = materials.options.sort((a,b) => a.label > b.label ? 1 : -1);

  return (
    <form className={"w-full"}>
      {!showroomId &&
        <div className="relative mb-3">
          <select id="showroom"
            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
            placeholder=" "
            value={formData["showroom"]}
            onChange={e => setFormData({...formData, "showroom": e.target.value})}>
            <option selected>{t("Selecciona el showroom")}</option>
            {showroomsList && showroomsList.map((showroom) =>
              <option key={showroom.id} value={showroom.uuid}>{showroom.nombre}</option>
            )}
          </select>
          <label htmlFor="showroom"
            className="absolute text-sm text-neutral-800 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-neutral-300 px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Showroom</label>
        </div>
      }
      {formErrors.showroom && (
        <div className="pl-5 mb-3 text-normal text-red-700">
          {t(formErrors.showroom)}
        </div>
      )}

      <div className="relative mb-3">
        <select id="tipo"
          className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
          placeholder=" "
          value={formData["tipo"]}
          onChange={e => setFormData({...formData, "tipo": e.target.value})}>
          <option selected>{t("Selecciona el tipo de material")}</option>
          <option value={"display"}>Display</option>
          <option value={"material"}>Material</option>
        </select>
        <label htmlFor="tipo"
          className="absolute text-sm text-neutral-800 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-neutral-300 px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t("Tipo de material")}</label>
      </div>
      {formErrors.tipo && (
        <div className="pl-5 mb-3 text-normal text-red-700">
          {t(formErrors.tipo)}
        </div>
      )}

      <div className={`mb-3 ${formData.tipo === "display" ? "relative" : "hidden"}`}>
        <select id="tipo-display"
          className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
          placeholder=" "
          value={formData["material"]}
          onChange={e => setFormData({...formData, "material": e.target.value})}>
          <option selected>{t("Selecciona el display")}</option>
          <option>{t("Torre")}</option>
          <option>{t("Display de 6 mm")}</option>
          <option>{t("Display de 12 mm")}</option>
        </select>
        <label htmlFor="tipo-display" className="absolute text-sm text-neutral-800 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-neutral-300 px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Display</label>
      </div>

      <div className={`mb-3 ${formData.tipo === "material" ? "relative" : "hidden"}`}>
        <Select id="tipo-material" options={mataerialOptions} value={formData["material"]}
          classNames={{
            control: () => "ascale-react-select block w-full text-sm text-gray-900 bg-transparent rounded-lg appearance-none focus:outline-none focus:ring-0 focus:border-black peer",
            option: () => "ascale-react-select-inner ascale-react-select-option",
            input: () => "ascale-react-select-input !my-0 !py-0",
            placeholder: () => "ascale-react-select-inner",
            menu: () => "!z-[999]",
            menuList: () => "ascale-react-select-menu-list",
            valueContainer: () => "!px-0 !py-0 !my-0",
            singleValue: () => "ascale-react-select-inner",
            indicatorsContainer: () => "!hidden",
            indicatorSeparator: () => "!hidden",
          }}
          placeholder={t("Selecciona un material")}
          onChange={e => setFormData({...formData, "material": e})} />
        <label htmlFor="tipo-material"
          className="absolute text-sm text-neutral-800 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-neutral-300 px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Material</label>
      </div>
      {formErrors.material && (
        <div className="pl-5 mb-3 text-normal text-red-700">
          {t(formErrors.material)}
        </div>
      )}

      {formData.material && (
        <div className="relative mb-3">
          <select id="material-acabado"
            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
            placeholder=" " value={formData["acabado"]}
            onChange={e => setFormData({...formData, "acabado": e.target.value})}>
            <option selected>{t("Selecciona el acabado")}</option>
            {["Pulido", "Soft Matt", "Matt"].map(a => (
              <option key={a} value={a}>{t(a)}</option>
            ))}
          </select>
          <label htmlFor="material-acabado"
            className="absolute text-sm text-neutral-800 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-neutral-300 px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t("Acabado")}</label>
        </div>
      )}
      {formErrors.acabado && (
        <div className="pl-5 mb-3 text-normal text-red-700">
          {t(formErrors.acabado)}
        </div>
      )}

      {formData.material && (
        <div className="relative mb-3">
          <select id="material-espesor"
            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
            placeholder=" " value={formData["espesor"]}
            onChange={e => setFormData({...formData, "espesor": e.target.value})}>
            <option selected>{t("Selecciona el espesor")}</option>
            {["6mm", "12mm", "20mm"].map(a => (
              <option key={a} value={a}>{t(a)}</option>
            ))}
          </select>
          <label htmlFor="material-espesor"
            className="absolute text-sm text-neutral-800 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-neutral-300 px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t("Espesor")}</label>
        </div>
      )}
      {formErrors.espesor && (
        <div className="pl-5 mb-3 text-normal text-red-700">
          {t(formErrors.espesor)}
        </div>
      )}

      <div className={`mb-3 ${formData.tipo === "material" ? "relative" : "hidden"}`}>
        <input id="cantidad" type="number"
          className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
          placeholder=" "
          value={formData["cantidad"]}
          onChange={e => setFormData({...formData, "cantidad": e.target.value})}/>
        <label htmlFor="ubicacion"
          className="absolute text-sm text-neutral-800 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-neutral-300 px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t("Cantidad")}</label>
      </div>
      {formErrors.cantidad && (
        <div className="pl-5 mb-3 text-normal text-red-700">
          {t(formErrors.cantidad)}
        </div>
      )}

      <div className="relative mb-3">
        <input id="fotografias" type="file" multiple  accept="image/*"
          className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
          placeholder=" "
          onChange={e => setFormData({...formData, "fotografias": e.target.files})}/>
        <label htmlFor="fotografias"
          className="absolute text-sm text-neutral-800 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-neutral-300 px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t("Fotografías")}</label>
      </div>
      {formErrors.fotografias && (
        <div className="pl-5 mb-3 text-normal text-red-700">
          {t(formErrors.fotografias)}
        </div>
      )}

      <div className="relative mb-3">
        <input id="condiciones" type="checkbox" checked={formData["condiciones"]} onChange={e => setFormData({...formData, "condiciones": e.target.checked})}
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
        <label htmlFor="condiciones" className="ml-2 text-sm font-medium text-black">
          {t("Acepto los")} <a href={t("/documents/Acuerdo-exposicion-Ascale-ES.pdf")} target="_blank" rel="noreferrer" className="text-blue-600 hover:underline">{t("términos y condiciones")}</a>.
        </label>
      </div>
      {formErrors.condiciones && (
        <div className="pl-5 mb-3 text-normal text-red-700">
          {t(formErrors.condiciones)}
        </div>
      )}

      <button
        className={"mt-3 px-4 py-2 rounded-full text-md text-black tracking-widest flex flex-row justify-center items-center border border-neutral-800 disabled:opacity-30"}
        type="button" onClick={handleNewMaterial} disabled={loading}>
        <span>{t("Crear material")}</span>
      </button>
      {formErrors.generic && (
        <div className="pl-5 my-4 text-normal text-red-700">
          {t(formErrors.generic)}
        </div>
      )}
    </form>
  )
}