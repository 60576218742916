import React from "react";
import { useTranslation } from "react-i18next";
import {useHistory, useParams} from "react-router-dom";

import { updateUser } from "../../services/user-service"
import EditUserForm from "../../forms/edit-user-form";

const UserEditPage = () => {
  const { userId } = useParams()

  const history = useHistory()
  const { t } = useTranslation()

  const onUserEdited = async () => {
    history.replace("/user-management")
  }

  return (
    <div className={"container mx-auto p-4 max-w-screen-md"}>
      <div className={"grid grid-cols-1 my-3"}>
        <div className={"flex flex-col justify-center my-5"}>
          <h2 className="text-2xl md:text-3xl font-medium tracking-[10px] text-black text-center uppercase">{ t("Edición usuario") }</h2>
        </div>
      </div>

      <div className={"grid grid-cols-1"}>
        <div className={"flex justify-center p-5"}>
          <EditUserForm userId={userId} updateUser={updateUser} onUserUpdated={onUserEdited} onCancel={onUserEdited}/>
        </div>
      </div>

    </div>
  )
}

export default UserEditPage;