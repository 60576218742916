import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next";
import { Toaster } from "react-hot-toast";

import { HiUser } from "react-icons/hi2";

import AuthService from "../services/auth-service";

const Layout = ({ currentPage, children }) => {
  const [openDropdown, setOpenDropdown] = useState(false)
  const { t } = useTranslation()
  const history = useHistory()

  const user = AuthService.getCurrentUser()

  const logout = () => {
    AuthService.logout()
    history.replace("/")
  }

  return (
    <>
      <Toaster toastOptions={{
        success: {
          iconTheme: {
            primary: "black",
            secondary: "white",
            duration: 3000,
          },
        },
      }}/>
      <div className={"container mx-auto p-4"}>
        <nav className="px-2 sm:px-4 py-2.5 rounded">
          <div className="container grid grid-cols-2 md:grid-cols-3 mx-auto">
            <a href="/" className="flex items-center">
              <img src="/assets/images/logo.svg" className="h-6 mr-3 sm:h-9" alt="Ascale"/>
            </a>
            <div className="items-center justify-center hidden w-full md:flex md:w-auto md:order-1" id="mobile-menu-2">
              <ul
                className="w-full flex flex-col p-4 mt-4 border border-gray-100 rounded-lg md:w-3/4 md:flex-row md:justify-between md:mt-0 md:text-lg md:font-medium md:border-0">
                <li className={"mx-2"}>
                  <a href="#"
                    onClick={() => {history.push("/showrooms")}}
                    className={`block py-2 px-3 text-gray-700 rounded md:bg-transparent ${currentPage === "HomePage" ? "font-bold" : "hover:font-bold font-medium"} md:p-0 tracking-[5px] uppercase`}>{t("Home")}</a>
                </li>
                <li className={"mx-2"}>
                  <a href="#"
                    onClick={() => {history.push("/mapa-showrooms")}}
                    className={`block py-2 px-3 text-gray-700 rounded md:bg-transparent ${currentPage === "ShowroomMapPage" ? "font-bold" : "hover:font-bold font-medium"} md:p-0 tracking-[5px] uppercase`}>{t("Mapa")}</a>
                </li>
                {user && user.dataValues.role > 0 && <li className={"mx-2"}>
                  <a href="#"
                    onClick={() => {history.push("/user-management")}}
                    className={`block py-2 px-3 text-gray-700 rounded md:bg-transparent ${currentPage === "UserManagementPage" ? "font-bold" : "hover:font-bold font-medium"} md:p-0 tracking-[5px] uppercase`}>{t("Usuarios")}</a>
                </li>}
              </ul>
            </div>
            <div className="flex items-center justify-end md:order-2">
              <button type="button"
                className="hidden md:block flex mr-3 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300"
                id="user-menu-button" aria-expanded="false" data-dropdown-toggle="user-dropdown"
                data-dropdown-placement="bottom"
                onClick={() => setOpenDropdown(!openDropdown)}>
                <span className="sr-only">Abrir user menu</span>
                <HiUser className={"text-3xl text-white p-1"}/>
              </button>
              {/*<!-- Dropdown menu -->*/}
              {openDropdown && (
                <div
                  className="bg-white z-50 my-4 text-base list-none divide-y divide-gray-100 rounded-lg shadow absolute right-5 xl:right-24 top-16"
                  id="user-dropdown">
                  <div className="px-4 py-3">
                    <span className="block text-sm text-gray-900 font-bold">{user.dataValues.username}</span>
                  </div>
                  <ul className="py-2" aria-labelledby="user-menu-button">
                    <li>
                      <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        onClick={() => {setOpenDropdown(false); history.push("/user-profile")}}
                      >{ t("Perfil") }</a>
                    </li>
                    <li>
                      <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        onClick={() => {setOpenDropdown(false); logout()}}
                      >{ t("Cerrar sesión") }</a>
                    </li>
                  </ul>
                </div>
              )}
              <button data-collapse-toggle="mobile-menu-2" type="button"
                className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
                aria-controls="mobile-menu-2" aria-expanded="false"
                onClick={() => setOpenDropdown(!openDropdown)}>
                <span className="sr-only">Abrir main menu</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"></path>
                </svg>
              </button>
            </div>
          </div>
        </nav>

        {children}
      </div>
    </>
  )
}

export default Layout
