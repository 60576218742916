import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import esTranslations from "./i18n/i18n-es.json";
import enTranslations from "./i18n/i18n-en.json";

const resources = {
  ...esTranslations,
  ...enTranslations
}

const user = JSON.parse(localStorage.getItem("user"))

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: user ? user.dataValues.language : "es",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;