import React, {useState} from "react";
import { useTranslation } from "react-i18next";

export default function ResetPasswordForm ({ resetPassword, onResetPassword}) {
  const { t } = useTranslation()

  const [formData, setFormData] = useState({
    password1: "",
    password2: ""
  })

  const [formErrors, setFormErrors] = useState({
    password: null
  })

  const handleResetPassword = async () => {
    if (formData.password1 !== formData.password2) {
      setFormErrors({
        password: "Las contraseñas no coinciden"
      })
    } else {
      await resetPassword(formData.password1)
      setFormData({
        password1: "",
        password2: ""
      })
      onResetPassword()
    }
  }

  return (
    <form className={"w-full md:w-3/5 lg:w-1/2"}>
      <div className="relative mb-3">
        <input id="password1" type="password"
          className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
          placeholder=" "
          value={formData["password1"]}
          onChange={e => setFormData({...formData, password1: e.target.value})}/>
        <label htmlFor="password1"
          className="absolute text-sm text-neutral-800 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{ t("Contraseña") }</label>
      </div>

      <div className="relative mb-3">
        <input id="password2" type="password"
          className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
          placeholder=" "
          value={formData["password2"]}
          onChange={e => {setFormData({...formData, password2: e.target.value})}}/>
        <label htmlFor="password2"
          className="absolute text-sm text-neutral-800 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{ t("Repite Contraseña") }</label>
      </div>
      {formErrors.password && (
        <div className="pl-5 mb-3 text-normal text-red-700">
          {t(formErrors.password)}
        </div>
      )}

      <button
        className={"mt-3 px-4 py-2 rounded-full text-md text-black tracking-widest flex flex-row justify-center items-center border border-neutral-800"}
        type="button" onClick={handleResetPassword}>
        <span>{ t("Resetear contraseña") }</span>
      </button>
    </form>
  )
}