import React, {useState, useEffect} from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

import { HiOutlineLocationMarker } from "react-icons/hi";

import showroomTipo from "../data/showroom-tipo.json"
import { useTranslation } from "react-i18next";

export default function ShowroomForm ({crearShowroom, onShowroomCreated}) {

  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)

  const {
    //placesService,
    placePredictions,
    getPlacePredictions,
    //isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: "AIzaSyBYk06A8KJR_4tC7KFIWIMGyvG7sPi5z3M",
    debounce: 1000,
    language: "es",
  });

  const [ubicacionTrusted, setUbicacionTrusted] = useState(false)

  const [formData, setFormData] = useState({
    nombre: "",
    ubicacion: "",
    ubicacionId: null,
    fotografia: null,
    tipo: "",
    telefono: "",
    email: "",
    condiciones: false,
  })

  const [formErrors, setFormErrors] = useState({
    nombre: null,
    ubicacion: null,
    fotografia: null,
    tipo: null,
    telefono: null,
    email: null,
    condiciones: null
  })

  useEffect(() => {
    if ((formData.ubicacion.length > 4) && !ubicacionTrusted) {
      getPlacePredictions({ input: formData.ubicacion });
    }
  }, [formData])

  const handleNewShowroom = () => {
    const {nombre, ubicacion, ubicacionId, tipo, telefono, email, condiciones} = formData
    let _formErrors = {
      nombre: null,
      ubicacion: null,
      fotografia: null,
      tipo: null,
      telefono: null,
      email: null,
      condiciones: null
    }
    let error = false

    if (!nombre || nombre.length < 2) {
      error = true
      _formErrors = {..._formErrors, nombre: "Introduce un nombre válido"}
    }
    if (!ubicacion || ubicacion.length < 2 || !ubicacionId || !ubicacionTrusted) {
      error = true
      _formErrors = {..._formErrors, ubicacion: "Introduce una dirección válida"}
    }
    if (!tipo || tipo.length < 2) {
      error = true
      _formErrors = {..._formErrors, tipo: "Introduce un tipo válido"}
    }
    if (!telefono || telefono.length < 2) {
      error = true
      _formErrors = {..._formErrors, telefono: "Introduce un teléfono válido"}
    }
    if (!email || email.length < 2) {
      error = true
      _formErrors = {..._formErrors, email: "Introduce una dirección válida"}
    }
    if (!condiciones) {
      error = true
      _formErrors = {..._formErrors, condiciones: "Acepta las condiciones"}
    }

    setFormErrors(_formErrors)

    if (!error) {
      crearShowroom(formData)
        .then(() => {
          setFormData({
            nombre: "",
            ubicacion: "",
            fotografia: null,
            tipo: "",
            telefono: "",
            email: "",
            condiciones: false
          })
          onShowroomCreated()
        })
        .catch(() => {
          setFormErrors({
            generic: "Error al crear showroom"
          })
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  return (
    <form className={"w-full"}>
      <div className="relative mb-3">
        <input id="nombre" type="text"
          className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
          placeholder=" "
          value={formData["nombre"]}
          onChange={e => setFormData({...formData, nombre: e.target.value})}/>
        <label htmlFor="nombre"
          className="absolute text-sm text-neutral-800 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-neutral-300 px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t("Nombre")}</label>
      </div>
      {formErrors.nombre && (
        <div className="pl-5 mb-3 text-normal text-red-700">
          {t(formErrors.nombre)}
        </div>
      )}

      <div className="relative mb-3">
        <input id="ubicacion" type="text"
          className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
          placeholder=" "
          value={formData["ubicacion"]}
          onChange={e => {setFormData({...formData, "ubicacion": e.target.value, "ubicacionId": null}); setUbicacionTrusted(false)}}/>
        <label htmlFor="ubicacion"
          className="absolute text-sm text-neutral-800 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-neutral-300 px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t("Dirección")}</label>
      </div>
      {!ubicacionTrusted && placePredictions && placePredictions.length > 0 &&
        <div className={"flex flex-col justify-center items-start ml-3 mb-4"}>
          {placePredictions.map((p, i) =>
            <span role={"button"} key={p.place_id} tabIndex={i}
              className={"flex flex-row justify-start items-center w-full px-1.5 py-1.5 text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black"}
              onKeyDown={() => {setFormData({...formData, "ubicacion": p.description, "ubicacionId": p.place_id}); setUbicacionTrusted(true)}}
              onClick={() => {setFormData({...formData, "ubicacion": p.description, "ubicacionId": p.place_id}); setUbicacionTrusted(true)}}>
              <HiOutlineLocationMarker className={"text-sm mb-1"}/>{p.description}
            </span>
          )}
        </div>
      }
      {formErrors.ubicacion && (
        <div className="pl-5 mb-3 text-normal text-red-700">
          {t(formErrors.ubicacion)}
        </div>
      )}

      <div className="relative mb-3">
        <input id="telefono" type="text"
          className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
          placeholder=" "
          value={formData["telefono"]}
          onChange={e => setFormData({...formData, "telefono": e.target.value})}/>
        <label htmlFor="telefono"
          className="absolute text-sm text-neutral-800 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-neutral-300 px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t("Teléfono")}</label>
      </div>
      {formErrors.telefono && (
        <div className="pl-5 mb-3 text-normal text-red-700">
          {t(formErrors.telefono)}
        </div>
      )}

      <div className="relative mb-3">
        <input id="email" type="email"
          className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
          placeholder=" "
          value={formData["email"]}
          onChange={e => setFormData({...formData, "email": e.target.value})}/>
        <label htmlFor="email"
          className="absolute text-sm text-neutral-800 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-neutral-300 px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t("Correo electrónico del cliente")}</label>
      </div>
      {formErrors.email && (
        <div className="pl-5 mb-3 text-normal text-red-700">
          {t(formErrors.email)}
        </div>
      )}

      <div className="relative mb-3">
        <select id="tipo"
          className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
          placeholder=" "
          value={formData["tipo"]}
          onChange={e => setFormData({...formData, "tipo": e.target.value})}>
          <option selected>{t("Selecciona un tipo de showroom")}</option>
          {
            Object.keys(showroomTipo.tipos).map((tipo) =>
              <option key={tipo} value={tipo}>{t(showroomTipo.tipos[tipo].literales.es)}</option>
            )
          }
        </select>
        <label htmlFor="tipo"
          className="absolute text-sm text-neutral-800 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-neutral-300 px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t("Tipo")}</label>
      </div>
      {formErrors.tipo && (
        <div className="pl-5 mb-3 text-normal text-red-700">
          {t(formErrors.tipo)}
        </div>
      )}

      <div className="relative mb-3">
        <input id="fotografia" type="file" accept="image/*"
          className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
          placeholder=" "
          onChange={e => setFormData({...formData, "fotografia": e.target.files[0]})}/>
        <label htmlFor="fotografia"
          className="absolute text-sm text-neutral-800 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-neutral-300 px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t("Fotografía")}</label>
      </div>
      {formErrors.fotografia && (
        <div className="pl-5 mb-3 text-normal text-red-700">
          {t(formErrors.fotografia)}
        </div>
      )}

      <div className="relative mb-3">
        <input id="condiciones" type="checkbox" checked={formData["condiciones"]} onChange={e => setFormData({...formData, "condiciones": e.target.checked})}
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
        <label htmlFor="condiciones" className="ml-2 text-sm font-medium text-black">
          {t("Acepto los")} <a href={t("/documents/Acuerdo-exposicion-Ascale-ES.pdf")} target="_blank" rel="noreferrer" className="text-blue-600 hover:underline">{t("términos y condiciones")}</a>.
        </label>
      </div>
      {formErrors.condiciones && (
        <div className="pl-5 mb-3 text-normal text-red-700">
          {t(formErrors.condiciones)}
        </div>
      )}

      <button
        className={"mt-3 px-4 py-2 rounded-full text-md text-black tracking-widest flex flex-row justify-center items-center border border-neutral-800 disabled:opacity-30"}
        type="button" onClick={handleNewShowroom} disabled={loading}>
        <span>{t("Crear showroom")}</span>
      </button>
      {formErrors.generic && (
        <div className="pl-5 my-4 text-normal text-red-700">
          {t(formErrors.generic)}
        </div>
      )}
    </form>
  )
}