import React, {useState} from "react";
//import {useHistory} from "react-router-dom";

import AuthService from "../../services/auth-service";

const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g; // eslint-disable-line

function LoginPage() {
  const [passwordReseted, setPasswordReseted] = useState(false)

  const [formDisplayed, setFormDisplayed] = useState("login")
  const [loading, setLoading] = useState(false)

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  })

  const [formErrors, setFormErrors] = useState({
    username: null,
    password: null,
  })

  //const history = useHistory()

  const handleLoginSubmit = (e) => {
    e.preventDefault()
    const _formErrors = {
      username: null,
      password: null,
    }

    let error = false

    if (formData.username.length === 0 || !formData.username.match(isValidEmail)) {
      error = true
      _formErrors.username = "Credenciales incorrectas"
    }

    if (formData.password.length === 0) {
      error = true
      _formErrors.password = "Credenciales incorrectas"
    }

    setFormErrors(_formErrors)

    if (!error) {
      setLoading(true)
      AuthService.login(formData.username, formData.password)
        .then(() => {
          //history.push("/showrooms")
          window.location = "/showrooms"
        })
        .catch(() => {
          setFormErrors({
            username: null,
            password: "Credenciales incorrectas"
          })
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const handleResetSubmit = (e) => {
    e.preventDefault()
    const _formErrors = {
      username: null,
      password: null,
    }

    let error = false

    if (formData.username.length === 0 || !formData.username.match(isValidEmail)) {
      error = true
      _formErrors.username = "Credenciales incorrectas"
    }

    setFormErrors(_formErrors)

    if (!error) {
      setLoading(true)
      AuthService.requestResetPassword(formData.username)
        .then(() => {
          setPasswordReseted(true)
        })
        .catch(console.error)
        .finally(() => {
          setLoading(false)
        })
    }
  }

  return (
    <div className={"container mx-auto h-screen p-4 max-w-screen-md"}>
      <div className={"h-full w-full flex flex-col justify-center items-center"}>
        <img src={"/assets/images/logo.svg"} alt="logo" className={"w-3/5 md:w-2/5 lg:w-1/5 my-5"}/>
        {formDisplayed === "login" &&
          <>
            <form className={"w-4/5 md:w-3/5 lg:w-1/2 my-5"}>
              <div className="relative mb-3">
                <input id="username" type="email"
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
                  placeholder=" "
                  value={formData["username"]}
                  onChange={e => setFormData({...formData, username: e.target.value})}/>
                <label htmlFor="username"
                  className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Usuario</label>
              </div>
              {formErrors.username && (
                <div className="pl-5 mb-3 text-normal text-red-700">
                  {formErrors.username}
                </div>
              )}

              <div className="relative mb-3">
                <input id="password" type="password"
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
                  placeholder=" "
                  value={formData["password"]}
                  onChange={e => setFormData({...formData, password: e.target.value})}/>
                <label htmlFor="password"
                  className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Contraseña</label>
              </div>
              {formErrors.password && (
                <div className="pl-5 mb-3 text-normal text-red-700">
                  {formErrors.password}
                </div>
              )}

              <button
                className={"mt-3 px-4 py-3 rounded-full text-md text-black tracking-widest flex flex-row justify-center items-center disabled:opacity-30"}
                type="submit" onClick={handleLoginSubmit} disabled={loading}>
                <span>Acceder</span>
              </button>
            </form>
            <span className={"p-4 my-5 text-sm text-center text-neutral-500"}>¿No recuerdas la contraseña? Haz click <a href={"#"} className="text-blue-600 hover:underline cursor-pointer" onClick={() => {setFormDisplayed("reset-password"); setFormErrors({})}}>aquí</a> para recuperarla</span>
          </>
        }

        {formDisplayed === "reset-password" && !passwordReseted &&
          <>
            <form className={"w-4/5 md:w-3/5 lg:w-1/2 my-5"}>
              <div className="relative mb-3">
                <input id="username" type="text"
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
                  placeholder=" "
                  value={formData["username"]}
                  onChange={e => setFormData({...formData, username: e.target.value})}/>
                <label htmlFor="username"
                  className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Usuario</label>
              </div>
              {formErrors.username && (
                <div className="pl-5 mb-3 text-normal text-red-700">
                  {formErrors.username}
                </div>
              )}

              <button
                className={"mt-3 px-4 py-3 rounded-full text-md text-black tracking-widest flex flex-row justify-center items-center disabled:opacity-30"}
                type="submit" onClick={handleResetSubmit} disabled={loading}>
                <span>Recuperar contraseña</span>
              </button>
            </form>
            <span className={"p-4 my-5 text-sm text-center text-neutral-500"}><a href={"#"} className="text-blue-600 hover:underline cursor-pointer" onClick={() => {setFormDisplayed("login"); setFormErrors({})}}>Iniciar sesión</a></span>
          </>
        }
        {formDisplayed === "reset-password" && passwordReseted &&
          <div className={"flex flex-col justify-center items-center my-5"}>
            <span className={"text-md text-black text-center mt-5 mb-3"}>Recibirás un correo electrónico con un enlace para restaurar tu contraseña.</span>
            <span className={"p-4 my-5 text-sm text-center text-neutral-500"}><a href={"#"} className="text-blue-600 hover:underline cursor-pointer" onClick={() =>  {setFormDisplayed("login"); setPasswordReseted(false)}}>Iniciar sesión</a></span>
          </div>
        }
      </div>
    </div>
  );
}

export default LoginPage;