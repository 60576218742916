import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import ModalImage from "react-modal-image";
import moment from "moment";
import toast from "react-hot-toast";

import { HiOutlineX, HiPencil, HiTrash } from "react-icons/hi";

import showroomTipo from "../../data/showroom-tipo.json"

import EmptyDataTable from "../../components/empty-data-table";
import MaterialForm from "../../forms/material-form";
import EditShowroomForm from "../../forms/edit-showroom-form";
import ShowroomMap from "../../components/showroom-map";
import AuthService from "../../services/auth-service";

const ShowroomPage = ({obtenerShowroom, crearMaterial, generarPdf, adjuntarPdf, editarShowroom, eliminarShowroom, eliminarMaterial}) => {
  const { t } = useTranslation()
  const history = useHistory()

  const [user, setUser] = useState(null)

  const [showroom, setShowroom] = useState(null)
  const [editShowroom, setEditShowroom] = useState(false)

  const [openMaterialForm, setOpenMaterialForm] = useState(false)

  const [materialColumns, setMaterialColumns] = useState([])
  const [materialesData, setMaterialesData] = useState([])

  const [pdfLink, setPdfLink] = useState(null)

  const [loading, setLoading] = useState(false);

  const { showroomId } = useParams()

  const hiddenFileInput = useRef(null);

  useEffect(() => {
    const columns = [
      {
        name: t("Tipo"),
        selector: row => row.tipo.charAt(0).toUpperCase() + row.tipo.slice(1),
        sortable: true
      },
      {
        name: t("Material"),
        grow: 4,
        selector: row => `${row.material} ${row.espesor} ${row.acabado}`,
        sortable: true
      },
      {
        name: t("Cantidad"),
        selector: row => row.cantidad || "N/A",
        sortable: true
      },
      {
        name: t("Fecha"),
        selector: row => moment(row.fecha).format("L LTS"),
        sortable: true,
        sortFunction: (a, b) => a.fecha > b.fecha ? 1 : -1
      },
      {
        name: t("Fotografías"),
        cell: (data) => (
          <div className={"w-full flex flex-row justify-center items-center"}>
            {data.fotografias && data.fotografias.map(f =>
              <ModalImage key={f} className={"w-10"}
                small={`/public/${f}`}
                large={`/public/${f}`}
                alt={data.material}
              />
            )}
            {(!data.fotografias || data.fotografias.length === 0) && <span>N/A</span>}
          </div>
        )
      },
      {
        name: "",
        cell: (data) => (
          <div className={"flex flew-row justify-center align-center"}>
            <button className={"mx-2"} title={t("Eliminar material")} onClick={() => handleRemoveMaterial(data.tipo, data.index)}><HiTrash/></button>
          </div>
        )
      },
    ];
    setMaterialColumns(columns)

    const user = AuthService.getCurrentUser()
    setUser(user)
  }, []);

  useEffect(() => {
    updateShowroom();
  }, [showroomId, obtenerShowroom])

  const updateShowroom = () => {
    setLoading(true)
    obtenerShowroom(showroomId)
      .then((showroom) => {
        setShowroom(showroom)
        if (showroom.pdfs.length > 0) {
          const pdf = showroom.pdfs.sort((a, b) => a.updatedAt > b.updatedAt ? 1 : -1).pop().fichero
          setPdfLink(`/docs/${pdf}`)
        } else {
          setPdfLink(null)
        }
        const showroomMateriales = showroom.materiales ? showroom.materiales.map((material, index) => ({
          tipo: "material",
          material: material.material,
          acabado: material.acabado || "",
          espesor: material.espesor || "",
          cantidad: material.cantidad,
          fecha: showroom.fecha_creacion,
          fotografias: material.fotografias,
          index
        })) : []
        const showroomDisplay = showroom.displays ? showroom.displays.map((display, index) => ({
          tipo: "display",
          material: display.tipo,
          acabado: "",
          espesor: "",
          cantidad: null,
          fecha: showroom.fecha_creacion,
          fotografias: display.fotografias,
          index
        })) : []

        setMaterialesData([...showroomMateriales, ...showroomDisplay])
        setLoading(false)
      })
      .catch((e) => {
        console.error(e)
        setLoading(false)
      })
  }

  const handleCreatePdf = () => {
    generarPdf(showroomId)
      .then(data => {
        const file = new Blob(
          [data],
          {type: "application/pdf"});
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch(console.log)
  }

  const handlePdfAttach = (event) => {
    const pdf = event.target.files[0]
    setLoading(true)
    adjuntarPdf(showroomId, pdf)
      .then(() => {
        toast.success(t("El PDF firmado se ha adjuntado con éxito"))
        updateShowroom()
      })
      .catch(() => {
        toast.error(t("Se ha producido un error al adjuntar el PDF firmado"))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleEditShowroom = (data) => {
    setLoading(true)
    return editarShowroom(showroom.id, data)
      .then(() => {
        toast.success(t("El showroom se ha editado con éxito"))
        updateShowroom()
      })
      .catch(() => {
        toast.error(t("Se ha producido un error al editar el showroom"))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleRemoveMaterial = (materialType, index) => {
    setLoading(true)
    eliminarMaterial(showroomId, materialType, index)
      .then(() => {
        toast.success(t("El material se ha eliminado con éxito"))
        updateShowroom()
      })
      .catch(() => {
        toast.error(t("Se ha producido un error al eliminar el material"))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleDeleteShowroom = async () => {
    if(window.confirm(t("¿Está seguro de eliminar el showroom?"))) {
      await eliminarShowroom(showroomId)
      history.replace("/showrooms")
    }
  }

  return showroom && (
    <div className={"container p-4"}>
      <div className={"max-w-screen-md mx-auto"}>
        {/* Showroom details */}
        <div className={"grid grid-cols-1 my-5"}>
          <div className={"flex flex-col justify-center"}>
            <h2 className="text-2xl md:text-3xl font-medium tracking-[10px] text-black text-center uppercase">{showroom.nombre}</h2>
            <span className={"text-sm text-center italic p-2"}>{t("Inscrito por")}: {showroom.user.username}</span>
            {!editShowroom && (
              <button className={"flex flex-row justify-center items-center"} title={t("Editar showroom")} onClick={() => setEditShowroom(true)}>
                <HiPencil className={"text-sm mb-1 mr-2"}/>
                <span>{t("Editar showroom")}</span>
              </button>
            )}
            {!editShowroom && user && user.dataValues.role > 1 && (
              <button className={"flex flex-row justify-center items-center"} title={t("Eliminar showroom")} onClick={() => handleDeleteShowroom(true)}>
                <HiTrash className={"text-sm mb-1 mr-2"}/>
                <span>{t("Eliminar showroom")}</span>
              </button>
            )}
          </div>
        </div>

        {editShowroom && (
          <div className={"grid grid-cols-1 my-5"}>
            <EditShowroomForm showroomData={showroom} editarShowroom={handleEditShowroom} onShowroomEdited={() => setEditShowroom(false)} onCancel={() => setEditShowroom(false)} />
          </div>
        )}

        <div className={"grid grid-cols-3 my-5"}>
          <div className={"flex flex-col justify-center items-end px-4"}>
            <img className={"w-full"} src={showroom.fotografia ? `/public/${showroom.fotografia}` : "/assets/images/default-foto.jpeg"} alt={showroom.nombre}/>
          </div>
          <div className={"flex flex-col justify-center items-start px-4 col-span-2"}>
            <div className={"grid grid-cols-3"}>
              <span className={"text-md text-right pr-2"}>{t("Tipo")}:</span>
              <span className={"text-md pl-2 col-span-2 capitalize"}>{showroomTipo.tipos[showroom.tipo].literales.es}</span>
              <span className={"text-md text-right pr-2"}>{t("Dirección")}:</span>
              <span className={"text-md pl-2 col-span-2"}>{showroom.ubicacion}</span>
              <span className={"text-md text-right pr-2"}>{t("Teléfono")}:</span>
              <span className={"text-md pl-2 col-span-2"}><a href={`tel:${showroom.telefono}`}>{showroom.telefono}</a></span>
              <span className={"text-md text-right pr-2"}>{t("Email")}:</span>
              <span className={"text-md pl-2 col-span-2"}><a href={`mailto:${showroom.email}`}>{showroom.email}</a></span>
              <span className={"text-md text-right pr-2"}>{t("Código")}:</span>
              <span className={"text-md pl-2 col-span-2"}>{showroom.codigo}</span>
              {pdfLink && (
                <>
                  <span className={"text-md text-right pr-2"}>{t("Pdf")}:</span>
                  <span className={"text-md pl-2 col-span-2"}>
                    <a target="_blank" rel="noreferrer" href={pdfLink} className="text-blue-600 hover:underline">{t("Documento")}</a>
                  </span>
                </>
              )}
            </div>
          </div>
        </div>

        <ShowroomMap
          showrooms={[showroom]}
          displayLegend={false}
          zoom={11}
          googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: "100%" }} />}
          containerElement={<div style={{ height: "70vh" }} />}
          mapElement={<div style={{ height: "100%" }} />}
        />

        <div className={"flex flex-col justify-center my-5"}>
          <div role="button" tabIndex={-1}
            className={"mx-3 px-4 py-3 text-[#46495A] text-md tracking-widest flex flex-row justify-center items-center"}
            onClick={handleCreatePdf}
            onKeyDown={handleCreatePdf}
          >
            <span>{t("Generar PDF")}</span>
          </div>
          <div role="button" tabIndex={-1}
            className={"mx-3 px-4 py-3 text-[#46495A] text-md tracking-widest flex flex-row justify-center items-center"}
            onClick={() => hiddenFileInput.current.click()}
            onKeyDown={() => hiddenFileInput.current.click()}
          >
            <span>{t("Adjuntar PDF")}</span>
          </div>
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handlePdfAttach}
            style={{display: "none"}}
          />
        </div>
      </div>

      <div className={"max-w-screen-lg mx-auto"}>
        {/* Materiales */}
        <div className={"grid grid-cols-1 my-3 mt-5"}>
          <div className={"flex justify-center my-5"}>
            <h2 className="text-xl font-medium tracking-[10px] text-black text-center uppercase">{t("Materiales")}</h2>
          </div>
        </div>

        <div className={`grid grid-cols-1 my-3 ${openMaterialForm ? "hidden" : "relative"}`}>
          <div className={"flex flex-row justify-center my-5"}>
            <div role="button" tabIndex={-1}
              className={""}
              onClick={() => {setOpenMaterialForm(true)}}
              onKeyDown={() => {setOpenMaterialForm(true)}}
            >
              <div className={"px-4 py-3 text-black text-md tracking-widest flex flex-row justify-center items-center border border-neutral-800 rounded-full"}>
                <span>{t("Nuevo material")}</span>
                <img src={"/assets/images/icon-plus.svg"} alt={"Nuevo material"} className={"ml-2 w-4 h-4"}/>
              </div>
            </div>
          </div>
        </div>

        <div className={`w-screen left-[50%] right-[50%] ml-[-50vw] mr-[-50vw] bg-neutral-300 mb-3 mt-5 ${openMaterialForm ? "relative" : "hidden"}`}>
          <div className={"container mx-auto p-4 max-w-screen-md"}>
            <div className={`grid grid-cols-1 my-3 ${openMaterialForm ? "relative" : "hidden"}`}>
              <div className={"flex justify-center my-3"}>
                <h2 className="flex flex-row text-md font-medium tracking-[10px] text-black text-center uppercase">Nuevo material<HiOutlineX className={"ml-3 mt-1 text-xl cursor-pointer"} onClick={() => {setOpenMaterialForm(false)}}/></h2>
              </div>
              <div className={"d-flex justify-content-center"}>
                <MaterialForm crearMaterial={crearMaterial} onMaterialCreated={()=> {updateShowroom(); setOpenMaterialForm(false);}} showroomId={showroomId}/>
              </div>
            </div>
          </div>
        </div>

        <DataTable
          className={"mt-5"}
          columns={materialColumns}
          data={materialesData}
          pagination
          progressPending={loading}
          noDataComponent={<EmptyDataTable/>}
        />
      </div>
    </div>
  )
}

export default ShowroomPage;