import React from "react";
import { Route, Redirect } from "react-router-dom";

const RouteGuard = ({ children, adminRoute, ...rest }) => {

  function hasJWT() {
    const user = JSON.parse(localStorage.getItem("user"))
    return user && !!user.token
  }

  function isAdmin() {
    const user = JSON.parse(localStorage.getItem("user"))
    return user && user.dataValues.role > 0
  }

  return (
    <Route {...rest}
      render={() => (
        hasJWT() ? ((adminRoute && isAdmin()) || !adminRoute ? children : <Redirect to={{ pathname: "/showrooms" }} /> ) : <Redirect to={{ pathname: "/login" }} />
      )}
    />
  );
};

export default RouteGuard;